import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactHookForm from 'react-hook-form';
import * as ReactI18next from 'react-i18next';
import * as ReactRouterDOM from 'react-router-dom';
import * as ReactTransitionGroup from 'react-transition-group';
import * as BifrostKeycloakClient from '@bifrost/keycloak-client';
import * as BifrostWebComponents from '@bifrost/web-components';
import * as BifrostWebTemplates from '@bifrost/web-templates';
import * as Connect from '@connectrpc/connect';
import * as ConnectQuery from '@connectrpc/connect-query';
import * as ConnectWeb from '@connectrpc/connect-web';
import * as CrowdinOTAClient from '@crowdin/ota-client';
import * as HookformResolvers from '@hookform/resolvers';
import * as ReactQuery from '@tanstack/react-query';
import * as DayJS from 'dayjs';
import * as I18next from 'i18next';
import * as Lodash from 'lodash';
import * as RcOverflow from 'rc-overflow';
import { registerApplication, start } from 'single-spa';
import * as Zod from 'zod';
import { createModuleDefinition } from '@utils/create_module_definition';
import 'systemjs';
import 'import-map-overrides';
import { queryClient } from './config/query_client';
import './index.styles.scss';
BifrostKeycloakClient.createClient({
    url: window.bifrostWeb.keycloak.url,
    realm: window.bifrostWeb.keycloak.realm,
    clientId: window.bifrostWeb.keycloak.clientId,
});
System.set('dependencies:react', React);
System.set('dependencies:react-dom', ReactDOM);
System.set('dependencies:react-router-dom', ReactRouterDOM);
System.set('dependencies:react-hook-form', ReactHookForm);
System.set('dependencies:react-transition-group', ReactTransitionGroup);
System.set('dependencies:dayjs', DayJS);
System.set('dependencies:lodash', Lodash);
System.set('dependencies:rc-overflow', RcOverflow);
System.set('dependencies:zod', Zod);
System.set('dependencies:@hookform/resolvers', HookformResolvers);
System.set('dependencies:@connectrpc/connect', Connect);
System.set('dependencies:@connectrpc/connect-query', ConnectQuery);
System.set('dependencies:@connectrpc/connect-web', ConnectWeb);
System.set('dependencies:@tanstack/react-query', ReactQuery);
System.set('dependencies:@bifrost/web-components', BifrostWebComponents);
System.set('dependencies:@bifrost/web-templates', BifrostWebTemplates);
System.set('dependencies:@bifrost/keycloak-client', BifrostKeycloakClient);
System.set('dependencies:i18next', I18next);
System.set('dependencies:react-i18next', ReactI18next);
System.set('dependencies:@crowdin/ota-client', CrowdinOTAClient);
window.bifrostWeb = {
    ...window.bifrostWeb,
    capabilities: [],
    navigate: path => window.history.pushState({}, '', path),
    getIsMobile: () => false,
};
const commonModuleProps = {
    landscape: window.bifrostWeb.landscape,
    backend: window.bifrostWeb.backend,
    queryClient,
};
const sharedModuleDefinition = createModuleDefinition('shared');
registerApplication({
    name: sharedModuleDefinition.name,
    app: () => System.import(sharedModuleDefinition.name),
    activeWhen: () => true,
    customProps: {
        ...commonModuleProps,
        modulePath: sharedModuleDefinition.url,
        appId: 'bcp',
    },
});
const portalNavigationModuleDefinition = createModuleDefinition('portal-navigation');
registerApplication({
    name: portalNavigationModuleDefinition.name,
    app: () => System.import(portalNavigationModuleDefinition.name),
    activeWhen: () => true,
    customProps: {
        ...commonModuleProps,
        modulePath: portalNavigationModuleDefinition.url,
    },
});
const portalDevicesModuleDefinition = createModuleDefinition('portal-devices');
registerApplication({
    name: portalDevicesModuleDefinition.name,
    app: () => System.import(portalDevicesModuleDefinition.name),
    activeWhen: portalDevicesModuleDefinition.activePath,
    customProps: {
        ...commonModuleProps,
        modulePath: portalDevicesModuleDefinition.url,
    },
});
const portalDashboardModuleDefinition = createModuleDefinition('portal-dashboard');
registerApplication({
    name: portalDashboardModuleDefinition.name,
    app: () => System.import(portalDashboardModuleDefinition.name),
    activeWhen: portalDashboardModuleDefinition.activePath,
    customProps: {
        ...commonModuleProps,
        modulePath: portalDashboardModuleDefinition.url,
    },
});
const companyModuleDefinition = createModuleDefinition('company');
registerApplication({
    name: companyModuleDefinition.name,
    app: () => System.import(companyModuleDefinition.name),
    activeWhen: companyModuleDefinition.activePath,
    customProps: {
        ...commonModuleProps,
        modulePath: companyModuleDefinition.url,
    },
});
const updaterModuleDefinition = createModuleDefinition('portal-updater');
registerApplication({
    name: updaterModuleDefinition.name,
    app: () => System.import(updaterModuleDefinition.name),
    activeWhen: updaterModuleDefinition.activePath,
    customProps: {
        ...commonModuleProps,
        modulePath: updaterModuleDefinition.url,
    },
});
start();
