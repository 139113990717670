import { createStylesheet } from './create_stylesheet';
import { getImportMapOverrides } from './get_import_map_overrides';
const removePortalPrefix = (moduleName) => {
    const prefix = 'portal-';
    return moduleName.startsWith(prefix) ? moduleName.substring(prefix.length) : moduleName;
};
export const createModuleDefinition = (moduleName) => {
    const { hostname, protocol } = window.location;
    const name = `@bifrost/${moduleName}`;
    const root = `/modules/${moduleName}`;
    const activePath = `/${removePortalPrefix(moduleName)}`;
    let url = `${protocol}//${hostname}${root}/module.js`;
    const overrides = getImportMapOverrides();
    const overrideUrl = overrides.imports[name];
    if (overrideUrl) {
        url = overrideUrl;
    }
    createStylesheet(url);
    return { name, root, activePath, url };
};
